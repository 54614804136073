import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

const PrivacyPolicyPage = ({ data, location: { pathname } }) => (
  <Layout meta={{ seo: data.datoCmsPage.seo, path: pathname }}>
    <section className="pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-8 col-md-10">
            <article className="article">
              <h1 className="text-center my-7 display-4">Privacy Policy</h1>

              <p>
                This privacy policy has been compiled to better serve those who are concerned with
                how their 'Personally Identifiable Information' (PII) is being used online. PII, as
                described in US privacy law and information security, is information that can be
                used on its own or with other information to identify, contact, or locate a single
                person, or to identify an inidual in context. Please read our privacy policy
                carefully to get a clear understanding of how we collect, use, protect or otherwise
                handle your Personally Identifiable Information in accordance with our website.{" "}
                <strong>
                  What personal information do we collect from the people that visit our blog,
                  website or app?
                </strong>{" "}
                When ordering or registering on our site, as appropriate, you may be asked to enter
                your name, email address or other details to help you with your experience.{" "}
                <strong>When do we collect information?</strong>
              </p>
              <p>
                 We collect information from you when you fill out a form, Use Live Chat or enter
                information on our site.
              </p>
              <p>
                <strong>How do we use your information?</strong>{" "}
              </p>
              <p>
                We may use the information we collect from you when you register, make a purchase,
                sign up for our newsletter, respond to a survey or marketing communication, surf the
                website, or use certain other site features in the following ways:
              </p>
              <p>
                {" "}
                <strong>•</strong> To personalize your experience and to allow us to deliver the
                type of content and product offerings in which you are most interested.{" "}
                <strong>•</strong> To improve our website in order to better serve you.{" "}
                <strong>•</strong> To allow us to better service you in responding to your customer
                service requests. <strong>•</strong> To send periodic emails regarding your order or
                other products and services. <strong>•</strong> To follow up with them after
                correspondence (live chat, email or phone inquiries){" "}
                <strong>How do we protect your information?</strong>{" "}
              </p>
              <p>
                Our website is scanned on a regular basis for security holes and known
                vulnerabilities in order to make your visit to our site as safe as possible.
              </p>
              <p> </p>
              <p>We use regular Malware Scanning.</p>
              <p>
                {" "}
                Your personal information is contained behind secured networks and is only
                accessible by a limited number of persons who have special access rights to such
                systems, and are required to keep the information confidential. In addition, all
                sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL)
                technology.
              </p>
              <p>
                We implement a variety of security measures when a user enters, submits, or accesses
                their information to maintain the safety of your personal information. 
              </p>
              <p>
                <strong>Do we use 'cookies'?</strong> Yes. Cookies are small files that a site or
                its service provider transfers to your computer's hard drive through your Web
                browser (if you allow) that enables the site's or service provider's systems to
                recognize your browser and capture and remember certain information. For instance,
                we use cookies to help us remember and process the items in your shopping cart. They
                are also used to help us understand your preferences based on previous or current
                site activity, which enables us to provide you with improved services. We also use
                cookies to help us compile aggregate data about site traffic and site interaction so
                that we can offer better site experiences and tools in the future.{" "}
                <strong>We use cookies to:</strong> <strong>•</strong> Understand and save user's
                preferences for future visits. <strong>•</strong> Keep track of advertisements.{" "}
                <strong>•</strong> Compile aggregate data about site traffic and site interactions
                in order to offer better site experiences and tools in the future. We may also use
                trusted third-party services that track this information on our behalf. You can
                choose to have your computer warn you each time a cookie is being sent, or you can
                choose to turn off all cookies. You do this through your browser settings. Since
                browser is a little different, look at your browser's Help Menu to learn the correct
                way to modify your cookies.{" "}
                <strong>If users disable cookies in their browser:</strong> If you turn cookies off
                it will turn off some of the features of the site.{" "}
                <strong>Third-party disclosure</strong>{" "}
              </p>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties your Personally
                Identifiable Information unless we provide users with advance notice. This does not
                include website hosting partners and other parties who assist us in operating our
                website, conducting our business, or serving our users, so long as those parties
                agree to keep this information confidential. We may also release information when
                it's release is appropriate to comply with the law, enforce our site policies, or
                protect ours or others' rights, property or safety. 
              </p>
              <p>
                However, non-personally identifiable visitor information may be provided to other
                parties for marketing, advertising, or other uses.
              </p>
              <p>
                {" "}
                <strong>Third-party links</strong> Occasionally, at our discretion, we may include
                or offer third-party products or services on our website. These third-party sites
                have separate and independent privacy policies. We therefore have no responsibility
                or liability for the content and activities of these linked sites. Nonetheless, we
                seek to protect the integrity of our site and welcome any feedback about these
                sites. <strong>Google</strong>{" "}
              </p>
              <p>
                Google's advertising requirements can be summed up by Google's Advertising
                Principles. They are put in place to provide a positive experience for users.
                https://support.google.com/adwordspolicy/answer/1316548?hl=en 
              </p>
              <p>
                {" "}
                We use Google AdSense Advertising on our website. Google, as a third-party vendor,
                uses cookies to serve ads on our site. Google's use of the DART cookie enables it to
                serve ads to our users based on previous visits to our site and other sites on the
                Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad
                and Content Network privacy policy.{" "}
                <strong>We have implemented the following:</strong> <strong>•</strong> Remarketing
                with Google AdSense <strong>•</strong> Google Display Network Impression Reporting{" "}
                <strong>•</strong> Demographics and Interests Reporting <strong>•</strong>
                 DoubleClick Platform Integration We, along with third-party vendors such as Google
                use first-party cookies (such as the Google Analytics cookies) and third-party
                cookies (such as the DoubleClick cookie) or other third-party identifiers together
                to compile data regarding user interactions with ad impressions and other ad service
                functions as they relate to our website. <strong>Opting out:</strong>
                <br />
                Users can set preferences for how Google advertises to you using the Google Ad
                Settings page. Alternatively, you can opt out by visiting the Network Advertising
                Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.  
              </p>
              <p>
                <strong>California Online Privacy Protection Act</strong>
              </p>
              <p>
                CalOPPA is the first state law in the nation to require commercial websites and
                online services to post a privacy policy. The law's reach stretches well beyond
                California to require any person or company in the United States (and conceivably
                the world) that operates websites collecting Personally Identifiable Information
                from California consumers to post a conspicuous privacy policy on its website
                stating exactly the information being collected and those iniduals or companies with
                whom it is being shared. - See more at:
                http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                 
              </p>
              <p>
                <strong>According to CalOPPA, we agree to the following:</strong>
              </p>
              <p>
                Users can visit our site anonymously. Once this privacy policy is created, we will
                add a link to it on our home page or as a minimum, on the first significant page
                after entering our website. Our Privacy Policy link includes the word 'Privacy' and
                can easily be found on the page specified above. You will be notified of any Privacy
                Policy changes: <strong>•</strong> On our Privacy Policy Page Can change your
                personal information: <strong>•</strong> By emailing us <strong>•</strong> By
                chatting with us or by sending us a support ticket  
              </p>
              <p>
                <strong>How does our site handle Do Not Track signals?</strong>
              </p>
              <p>
                We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising
                when a Do Not Track (DNT) browser mechanism is in place.  
              </p>
              <p>
                <strong>Does our site allow third-party behavioral tracking?</strong>
              </p>
              <p>It's also important to note that we allow third-party behavioral tracking  </p>
              <p>
                <strong>COPPA (Children Online Privacy Protection Act)</strong>
              </p>
              <p></p>
              <p>
                When it comes to the collection of personal information from children under the age
                of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents
                in control. The Federal Trade Commission, United States' consumer protection agency,
                enforces the COPPA Rule, which spells out what operators of websites and online
                services must do to protect children's privacy and safety online.
              </p>
              <p> We do not specifically market to children under the age of 13 years old.  </p>
              <p>
                <strong>Fair Information Practices</strong>
              </p>
              <p></p>
              <p>
                The Fair Information Practices Principles form the backbone of privacy law in the
                United States and the concepts they include have played a significant role in the
                development of data protection laws around the globe. Understanding the Fair
                Information Practice Principles and how they should be implemented is critical to
                comply with the various privacy laws that protect personal information.
              </p>
              <p>
                {" "}
                <strong>
                  In order to be in line with Fair Information Practices we will take the following
                  responsive action, should a data breach occur:
                </strong>{" "}
                We will notify you via email <strong>•</strong> Within 7 business days We will
                notify the users via in-site notification <strong>•</strong> Within 7 business days
                We also agree to the Inidual Redress Principle which requires that iniduals have the
                right to legally pursue enforceable rights against data collectors and processors
                who fail to adhere to the law. This principle requires not only that iniduals have
                enforceable rights against data users, but also that iniduals have recourse to
                courts or government agencies to investigate and/or prosecute non-compliance by data
                processors.  
              </p>
              <p>
                <strong>CAN SPAM Act</strong>
              </p>
              <p></p>
              <p>
                The CAN-SPAM Act is a law that sets the rules for commercial email, establishes
                requirements for commercial messages, gives recipients the right to have emails
                stopped from being sent to them, and spells out tough penalties for violations.
              </p>
              <p>
                {" "}
                <strong>We collect your email address in order to:</strong> <strong>•</strong> Send
                information, respond to inquiries, and/or other requests or questions{" "}
                <strong>•</strong> Market to our mailing list or continue to send emails to our
                clients after the original transaction has occurred.{" "}
                <strong>To be in accordance with CANSPAM, we agree to the following:</strong>{" "}
                <strong>•</strong> Not use false or misleading subjects or email addresses.{" "}
                <strong>•</strong> Identify the message as an advertisement in some reasonable way.{" "}
                <strong>•</strong> Include the physical address of our business or site
                headquarters. <strong>•</strong> Monitor third-party email marketing services for
                compliance, if one is used. <strong>•</strong> Honor opt-out/unsubscribe requests
                quickly. <strong>•</strong> Allow users to unsubscribe by using the link at the
                bottom of each email.{" "}
                <strong>
                  <br />
                  If at any time you would like to unsubscribe from receiving future emails, you can
                  email us at
                </strong>{" "}
                <strong>•</strong> Follow the instructions at the bottom of each email.
              </p>
              <p>
                and we will promptly remove you from <strong>ALL</strong> correspondence.
              </p>
              <p>
                {" "}
                <strong>Contacting Us</strong>{" "}
              </p>
              <p>
                If there are any questions regarding this privacy policy, you may contact us using
                the information below: admin@adamfard.com
              </p>
              <p> Last updated: November 25, 2019</p>
            </article>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default PrivacyPolicyPage;

export const query = graphql`
  query PrivacyPolicyPage {
    datoCmsPage(slug: { eq: "privacy-policy" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
